import CardProperties from "../../types/CardProperties"
import React from "react"
import {Box, Button, Grid, Paper, Typography} from "@material-ui/core"
import Link from "@material-ui/core/Link"
import moment from "moment"
import {useStyles} from "./CardStyles"

const Card = (props: CardProperties) => {
    const classes = useStyles()

    const card = props.card
    const list = props.lists.find( list => list.id === card.idList!)

    /**
     * Return true if the current time is after due time
     */
    function isDueDateTimeBeforeCurrentTime() {
        return moment(moment(card.due).format('HH:mm:ss.SSS'), 'HH:mm:ss.SSS').isBefore(
            moment(moment().format('HH:mm:ss.SSS'), 'HH:mm:ss.SSS')
        )
    }

    /**
     * Return true if the current time is before due time
     */
    function isDueDateTimeAfterCurrentTime() {
        return moment(moment(card.due).format('HH:mm:ss.SSS'), 'HH:mm:ss.SSS').isAfter(
            moment(moment().format('HH:mm:ss.SSS'), 'HH:mm:ss.SSS')
        )
    }

    /**
     * Calculate the number of days when a card will due
     * @param roundedDueDays number of days calculated
     */
    function calculateFutureDueDate(roundedDueDays: number) {
        let dueDaysAbs = Math.abs(roundedDueDays)
        if(isDueDateTimeBeforeCurrentTime()) dueDaysAbs++
        return dueDaysAbs
    }

    /**
     * Return true if the card Due date is today
     */
    function isDueDateOnCurrentDay() {
        return moment(moment(card.due).format('DD/MM/YYYY'), 'DD/MM/YYYY').isSame(
            moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY')
            )
    }

    /**
     * Generate a button based on number of days between due date and today (UTC time)
     */
    const generateDueButton = () => {
        const now = moment().utc()
        const dueDays = now.diff(moment(card.due), 'days', true)
        let roundedDueDays = now.diff(moment(card.due), 'days')

        if(card.dueComplete){
            return <Button variant="contained" id={`dueButton-${list?.id}`} className={classes.dueFuture}>
                Complete
            </Button>
        }

        if (dueDays < 0 || ((dueDays < 1) && isDueDateOnCurrentDay())) {
            if(roundedDueDays === 0 && isDueDateOnCurrentDay()) {
                return <Button variant="contained" id={`dueButton-${list?.id}`} className={classes.dueToday}>
                    Due today
                </Button>
            } else {
                const dueDaysAbs = calculateFutureDueDate(roundedDueDays)
                return <Button variant="contained" id={`dueButton-${list?.id}`} className={classes.dueFuture}>
                    Due in {dueDaysAbs} day{dueDaysAbs > 1 ? 's' : ''}
                </Button>
            }

        } else {
            if(isDueDateTimeAfterCurrentTime()) roundedDueDays++
            return <Button variant="contained" id={`dueButton-${list?.id}`} className={classes.duePassed}>
                Due {roundedDueDays} day{roundedDueDays > 1 ? 's' : ''} ago
            </Button>
        }
    }

    return (
        <Grid item xs={11} >
            <Paper elevation={6} className="card-element">
                <Link href={card.url} target='_blank' style={{ textDecoration: 'none'}} id={`cardLink-${card?.id}`}>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={9}>
                            <Box ml={2}>
                                <Box pt={1}>
                                    <Typography gutterBottom variant="subtitle1" align='left' id={`listName-${list?.id}`} className={classes.listName}>
                                        {list?.name}
                                    </Typography>
                                </Box>
                                <Box pb={1}>
                                    <Typography align='left' id={`cardName-${card.id}`} className={classes.cardName}>
                                        {card.name}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box mr={2} mt={2} mb={2}>
                                {generateDueButton()}
                            </Box>
                        </Grid>
                    </Grid>
                </Link>
            </Paper>
        </Grid>
    )
}

export default Card
