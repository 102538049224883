import React from "react";
import ReactDOM from "react-dom";
// Import the views your app loads
import Connector from "./views/Connector";

import {appDetailsState, LoadAnalytics, Onboarding, Settings, writeLog,} from "trello-shared-resources";
import ErrorComponent from "trello-shared-resources/dist/components/onboarding/error/ErrorComponent";
// Globally load the ant design css
import "antd/dist/antd.css";
// Load the main Orah CSS
import "./css/main.css";
import "trello-shared-resources/dist/assets/fonts/font.css";
import LicensedDueNext from "./views/LicensedDueNext";

/*
  Parse query string for "apptype" and route to corresponding view
*/
const queryString = require("query-string");
const parsedQueryString = queryString.parse(window.location.search);
let iframePassedAppType = parsedQueryString["apptype"];
writeLog("Iframe was passed apptype : " + iframePassedAppType);

LoadAnalytics();

switch (iframePassedAppType) {
  case "onboarding":
    // This automatically loads when your app is installed
    ReactDOM.render(
      <Onboarding context={appDetailsState} />,
      document.getElementById("root")
    );
    break;
  case "settings":
    // This is the settings page for your app
    ReactDOM.render(
      <Settings context={appDetailsState} />,
      document.getElementById("root")
    );
    break;
    // This loads the Due Next power-up
  case "due-next":
    ReactDOM.render(
      <LicensedDueNext />,
      document.getElementById("root")
    );
    break;
  case 'error':
    ReactDOM.render(<ErrorComponent />, document.getElementById('root'));
    break
  default:
    // We not pass a querystring param for the App Connector
    // This connector is used to instantiate your app's capabilities
    ReactDOM.render(<Connector />, document.getElementById("root"));
    break;
}
