import {useEffect} from 'react'
import {initializeCapabilities, PageView} from 'trello-shared-resources'
import {TrackActionEvent} from 'trello-shared-resources/dist/index'

/* 
    The Connector is used to tell Trello what capabilities your power-up should have. 
    This Connector should be used to add new menu items, buttons and other similar capabilities.
*/
const Connector = () => {
    const capabilities = {
        'board-buttons': function (t: any, options: any) {
            return [
                {
                    text: 'Due Next',
                    icon: './icons/due-next-board-button.svg',
                    callback: function (t: any, opts: any) {
                        const context = t.getContext()
                        TrackActionEvent('Button Click', context, {
                            board_id: context.board,
                            button: 'Open Due_Next'
                        })
                        return t.modal({
                            url: t.signUrl('./index.html?apptype=due-next'),
                            accentColor: '#EBEDF0',
                            fullscreen: false,
                            height: 700,
                            resizable: false,
                            title: 'Due Next by Orah Apps',
                            callback: function (t: any, opts: any) {
                                const context = t.getContext()
                                TrackActionEvent('Button Click', context, {
                                    board_id: context.board,
                                    button: 'Close Due_Next'
                                })
                            },
                        })
                    },
                },
            ]
        },
    }

    useEffect(() => {
        PageView('PowerUp Connector')
    }, [])

    initializeCapabilities(capabilities)

    return null
}

export default Connector
