import React from 'react';
import {Page, Text, View, Document, StyleSheet, pdf, Font} from '@react-pdf/renderer';
import Card from "trello-shared-resources/dist/types/Card";
import Board from "trello-shared-resources/dist/types/Board";
import moment from "moment";
import List from "trello-shared-resources/dist/types/List";
import SaveFile from "trello-shared-resources/dist/modules/file/SaveFile";

const FILE_TYPE = 'application/pdf;charset=utf-8;'

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4',
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontSize: 12,
        fontFamily: 'Open Sans'
    },
    section: {
        margin: 10,
        padding: 10,
    },
    cardName: {
        fontWeight: 'bold',
        fontSize: 14,
    }
});

const DueNextPDF = (listCards: Card[], lists: List[]) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View>
                {listCards.map(card => (
                    <View style={styles.section} key={card.id}>
                        <Text style={styles.cardName}>{card.name}</Text>
                        <Text>List: {lists.find(list => list.id === card.idList)!.name}</Text>
                        <Text>Labels: {card.labels !== undefined && card.labels.length > 0 ?
                         card.labels?.map(label => `${label.name} (${label.color ? label.color : 'no color'})`).join(', ') :
                         'No label'}</Text>
                        {card.members && card.members.length > 0 ? <Text>Members: {card.members.map(member => member.username).join(', ')}</Text> : null}
                         <Text>Due: {card.due}</Text>
                    </View>
                ))}
            </View>
        </Page>
    </Document>
);


/**
 * Export given list of cards to PDF file
 * It also register open-sans font to allow to use bold style
 * @param {*} listCards list of Card to export
 * @param {*} lists list of List to get card list name
 * @param {*} board Board info to get board name
 */
const exportToPDF = (listCards: Card[], lists: List[], board: Board, setErrorTitleExporting: (errorExporting: string) => void, setErrorSubtitleExporting: (errorExporting: string) => void) => {
    try {
        Font.register({
            family: 'Open Sans',
            fonts: [
                { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
                { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
            ]
        });
        const saveFile = new SaveFile(FILE_TYPE)
        const currentDate = moment().format('YYYYMMDD')
        const fileName = board.name + ' ' + currentDate + '.pdf'

        pdf(DueNextPDF(listCards, lists)).toBlob().then( blob => {
            saveFile.saveBlobToFile(fileName, blob)
        })
    } catch (e) {
        setErrorTitleExporting("Can't generate the PDF!")
        setErrorSubtitleExporting("Please try again. If the issue persists, contact our support team so we can have a closer look.")
    }
}

export default exportToPDF